define('quickPrices',[
    'jquery',
    'Magento_Catalog/js/price-utils',
    'underscore',
    'jquery/jquery-storageapi'
], function ($, priceUtils, _) {
    'use strict';

    return function (config) {
        var INCOMM = {},
            predefinedPrices = $('.predefined-price'),
            defaultPredefinedPrice = $('.predefined-price[data-default-price]'),
            priceWrapper = $('.product-info-price').find('.price-wrapper'),
            priceMeta = $('meta[itemprop="price"]'),
            currentPrice = $('#current-value'),
            priceInput = $('#custom-price-user-defined'),
            priceInputText = $('.custom-price-user-defined-text'),
            saveInput = $('#save-amount'),
            minPrice = config.minPrice,
            maxPrice = config.maxPrice,
            priceFormat = config.priceFormat,
            currentCoverId = $('[name="giftcard_cover_product_id"]').val(),
            storage = $.initNamespaceStorage('mage-giftcard-storage').sessionStorage,
            localStorage = $.initNamespaceStorage('mage-cache-storage').localStorage,
            KEY_DOT = '.';

        INCOMM.CustomPrices = {

            predefinedPrices: predefinedPrices,
            defaultPredefinedPrice: defaultPredefinedPrice,
            priceWrapper: priceWrapper,
            priceMeta: priceMeta,
            currentPrice: currentPrice,
            priceInput: priceInput,
            priceInputText: priceInputText,
            min: minPrice,
            max: maxPrice,
            priceFormat: priceFormat,
            saveInput: saveInput,
            isValidKey: true,
            currentCoverId: currentCoverId,

            init: function () {
                var that = INCOMM.CustomPrices;
                that.predefinedPrices.on('click', function (e) {
                    e.preventDefault();
                    that.selectPredefinedPrice($(this), $(this).data('value'));
                });
                that.priceInput.on('keydown', function (e) {
                    return this.checkInputVal(e);
                }.bind(that));

                that.priceInput.on('keyup input', function () {
                    that.adjustUserDefinedPriceBlock();
                });
                that.priceInput.on('blur', function () {
                    that.adjustUserDefinedPriceInput();
                    that.adjustUserDefinedPriceBlock();
                });

                that.setSelectedPrice();
            },

            setSelectedPrice: function () {
                var that = INCOMM.CustomPrices,
                    price = storage.get('price') || null,
                    saleablePredefinedPrice,
                    productInCart = storage.get('giftcard_cover_product_id'),
                    isCurrentCover = productInCart !== undefined && productInCart === this.currentCoverId;

                if (isCurrentCover && price && storage.get('PZN') !== false) {
                    saleablePredefinedPrice = $('.predefined-price').filter('[data-value="' + price + '"]');

                    if (saleablePredefinedPrice.length) {
                        saleablePredefinedPrice.click();
                    } else {
                        that.priceInput.val(price);
                        that.priceInputText.addClass('active');
                        that.priceInput.trigger('blur');
                        that.priceInput.trigger('keyup');
                        that.priceInput.trigger('change');
                    }
                } else {
                    defaultPredefinedPrice.click();
                }
            },

            isCurrentCoverInCart: function () {
                var that = INCOMM.CustomPrices,
                    cart,
                    items;

                cart = localStorage.get('cart');

                if (cart !== undefined) {
                    items = cart.items;

                    if (items !== undefined && items.length) {
                        return items[0].gift_cover_product_id == that.currentCoverId;
                    }
                }

                return false;
            },

            getPriceFromCart: function () {
                var cart,
                    items;

                cart = localStorage.get('cart');

                if (cart !== undefined) {
                    items = cart.items;

                    if (items !== undefined && items.length) {
                        return items[0].product_price_value;
                    }
                }

                return false;
            },

            checkInputVal: function (e) {
                var key = e.key;

                this.isValidKey = false;

                if (key === 'Backspace' || key === 'Delete' ||
                    key === 'Tab' || key === 'Escape' || key === 'ArrowLeft' || key === 'ArrowRight' || e.originalEvent.inputType === 'deleteContentBackward' ||
                    e.originalEvent.inputType === 'insertLineBreak' || e.altKey || e.ctrlKey || this.isSelectionInput()) {
                    return true;
                }

                if (!isNaN(parseInt(key, 10)) && (!e.altKey || !e.ctrlKey) || key === KEY_DOT) {
                    this.isValidKey = true;

                    if (this.priceInput.val().length === 7) {
                        this.isValidKey = false;
                    }

                    if (this.priceInput.val().length === 0 && (key === '0' || key === KEY_DOT)) {
                        this.isValidKey = false;
                    }

                    if (/(\d+\.)/i.test(this.priceInput.val())) {
                        if (key === KEY_DOT) {
                            this.isValidKey = false;
                        }
                    }

                    if (/(\d+\.\d\d)/i.test(this.priceInput.val())) {
                        this.isValidKey = false;
                    }
                }

                return this.isValidKey;
            },

            isSelectionInput: function () {
                var input = document.querySelector('#' + this.priceInput.attr('id')),
                    selectionStart = input.selectionStart,
                    selectionEnd = input.selectionEnd,
                    isSelected = false;

                if (selectionStart !== selectionEnd) {
                    isSelected = true;
                }

                return isSelected;
            },

            setPrice: function (value) {
                var price = this.getFormattedPrice(value, 2);

                $.initNamespaceStorage('mage-giftcard-storage').sessionStorage.set('price', value);
                this.priceWrapper.attr('data-price-amount', value);
                $('.price-wrapper span.price').html(price);
                this.priceMeta.attr('content', value);
                this.currentPrice.html(price);
                this.saveInput.val(value);
            },

            formatPrice: function (value) {
                var inputPriceFormat = _.clone(this.priceFormat);

                if (value) {
                    inputPriceFormat.pattern = '%s';
                    inputPriceFormat.groupSymbol = '.';
                    this.priceInput.val(priceUtils.formatPrice(value, inputPriceFormat));
                } else {
                    this.priceInput.val('');
                }
            },

            showErrorBlock: function (message) {
                this.customPriceText = this.priceInputText.text();
                this.priceInputText.addClass('custom-price-error error');
                this.priceInputText.text(message);
            },

            removeErrorBlock: function () {
                this.priceInputText.removeClass('custom-price-error error');
                this.priceInputText.text(this.customPriceText);
            },

            clearInput: function () {
                this.priceInput.val('');
                this.priceInputText.removeClass('active');
                this.removeErrorBlock();
            },

            getFormattedPrice: function (price, precision) {
                var format = this.priceFormat;
                format.precision = precision;
                format.requiredPrecision = precision;

                return priceUtils.formatPrice(price, format);
            },

            adjustUserDefinedPriceBlock: function () {
                // adjust price input environment
                // depending on its value

                var inputValue = this.priceInput.val(),
                    min = parseFloat(this.min),
                    max = parseFloat(this.max),
                    minPrice = this.getFormattedPrice(min, 0),
                    maxPrice = this.getFormattedPrice(max, 0);

                this.removeErrorBlock();

                switch (true) {
                    case inputValue == '':
                        break;

                    case inputValue < min:
                        this.setPrice(inputValue);
                        this.showErrorBlock(minPrice + ' minimum');
                        this.predefinedPrices.removeClass('selected');
                        break;

                    case inputValue > max:
                        this.setPrice(inputValue);
                        this.showErrorBlock(maxPrice + ' maximum');
                        this.predefinedPrices.removeClass('selected');
                        break;
                    default:
                        this.setPrice(inputValue);
                        this.predefinedPrices.removeClass('selected');

                        return;
                }
            },

            adjustUserDefinedPriceInput: function () {
                // adjust price input environment
                // depending on its value

                var inputValue = this.priceInput.val(),
                    min = parseFloat(this.min),
                    max = parseFloat(this.max),
                    actualValue = inputValue;

                switch (true) {
                    case inputValue == '':
                        this.defaultPredefinedPrice.trigger('click');
                        this.priceInput.val('');
                        break;

                    case inputValue < min:
                        this.priceInput.val(min);
                        this.setPrice(min);
                        this.formatPrice(min);
                        actualValue = min;
                        break;

                    case inputValue > max:
                        this.priceInput.val(max);
                        this.setPrice(max);
                        this.formatPrice(min);
                        actualValue = max;
                        break;
                }

                this.formatPrice(actualValue);
            },

            selectPredefinedPrice: function (element, value) {
                this.clearInput();
                this.setPrice(value);
                this.predefinedPrices.removeClass('selected');
                element.addClass('selected');
            }
        };

        var CustomPrices = INCOMM.CustomPrices;
        CustomPrices.init();
    };
});

