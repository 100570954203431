/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

/**
 * @api
 */
define('Magento_Theme/js/view/messages',[
    'jquery',
    'uiComponent',
    'Magento_Customer/js/customer-data',
    'underscore',
    'escaper',
    'jquery/jquery-storageapi'
], function ($, Component, customerData, _, escaper) {
    'use strict';

    return Component.extend({
        defaults: {
            cookieMessages: [],
            messages: [],
            allowedTags: ['div', 'span', 'b', 'strong', 'i', 'em', 'u', 'a']
        },

        /**
         * Extends Component object by storage observable messages.
         */
        initialize: function () {
            var _self = this;
            this._super();

            this.cookieMessages = _.unique($.cookieStorage.get('mage-messages'), 'text');
            this.messages = customerData.get('messages').extend({
                disposableCustomerData: 'messages'
            });

            if (this.cookieMessages.length || this.messages.length) {
                this.scrollTop();
            }

            if (!_.isEmpty(this.messages().messages)) {
                customerData.set('messages', {});
            }

            $.cookieStorage.set('mage-messages', '');
        },

        /**
         * Scroll to the top of the page.
         *
         * @return void
         */
        scrollTop: function () {

            if (document.readyState === 'complete') {
                $('html, body').animate({
                    scrollTop: 0
                }, 'slow');
            } else {
                $(document).on('readystatechange', function () {
                    if (document.readyState === 'complete') {
                        $('html, body').animate({
                            scrollTop: 0
                        }, 'slow');
                    }
                });
            }
        },

        /**
         * Prepare the given message to be rendered as HTML
         *
         * @param {String} message
         * @return {String}
         */
        prepareMessageForHtml: function (message) {
            return escaper.escapeHtml(message, this.allowedTags);
        }
    });
});

