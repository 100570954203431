( function( factory ) {
    "use strict";

    if ( typeof define === "function" && define.amd ) {

        // AMD. Register as an anonymous module.
        define( 'jquery-ui-modules/version',[ "jquery" ], factory );
    } else {

        // Browser globals
        factory( jQuery );
    }
} )( function( $ ) {
    "use strict";

    $.ui = $.ui || {};

    return $.ui.version = "1.13.2";

} );

