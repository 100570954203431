define('js/main-navigation',[
    'jquery'
    ], function ($) {
    'use strict';

    /**
     * Navigation Widget - this widget is responsible for main navigation desktop and mobile views
     */
    $.widget('incomm.nav', {
        options: {
            triggerEvent: 'click',
            btnOpen: '[data-action="main-nav-trigger"]',
            btnClose: '[data-action="main-nav-close"]',
            navContainer: '[data-role="main-nav-container"]',
            windowShadow: '[data-role="window-shadow"]',
            navOpenClass: '_active',
            breakpoint: 768
        },

        /**
         * Constructor
         */
        _create: function () {
            this.open();
            this.close();
            this.resize();
        },

        /**
         * Attaches open event to button and adds shadow to page
         */
        open: function () {
            var _self = this;

            $(_self.options.btnOpen).on(_self.options.triggerEvent, function () {
                $(_self.options.windowShadow).fadeIn();
                setTimeout(function () {
                    $(_self.options.navContainer).addClass(_self.options.navOpenClass);
                }, 300);
            });
        },

        /**
         * Attaches close event to button and removes shadow from page
         */
        close: function () {
            var _self = this;

            $(_self.options.btnClose).on(_self.options.triggerEvent, function () {
                $(_self.options.navContainer).removeClass(_self.options.navOpenClass);
                setTimeout(function () {
                    $(_self.options.windowShadow).fadeOut();
                }, 300);
            });
        },

        /**
         * Removes window shadow on screens wider than breakpoint
         */
        resize: function () {
            var _self = this,
                windowWidth = $(window).width();

            $(window).on('resize', function () {
                if (windowWidth > _self.options.breakpoint) {
                    $(_self.options.windowShadow).hide();
                }
            });
        }

    });

    return $.incomm.nav;
});

