define('InComm_GiftCard/js/manage-giftcard-form',[
    'jquery',
    'mage/translate',
    'lib/md5',
    'Magento_Customer/js/customer-data',
    'InComm_Login/js/customer',
    'quickPrices',
    'jquery/jquery-storageapi',
    'mage/cookies'
], function ($, $t, md5, customerData, customer) {
    'use strict';

    $.widget('incomm.manageGiftcardForm', {
        options: {
            PZN: null,
            defaultMessage: 'Hope you enjoy this Walmart gift card!'
        },
        storage: $.initNamespaceStorage('mage-giftcard-storage').sessionStorage,
        localStorage: $.initNamespaceStorage('mage-cache-storage').localStorage,
        currentCoverId: $('[name="giftcard_cover_product_id"]').val(),

        /**
         * Create widget.
         *
         * @return {void}
         * @private
         */
        _create: function () {
            if (this._isNeedToSetPznFlag()) {
                this._setPznFlag();
                return;
            }

            this._manageCid();
            this._setDefaults();
            this._fillForm();
            this._initHandlers();
            this._setPznFlag(true);
        },

        /**
         * Manage *CID* stuff.
         *
         * @return {void}
         * @private
         */
        
        _manageCid: function () {
            if ((this._isCidWasChanged() && this._getPreviousCidHash() !== this._getGuestCidHash())
                || this._isPznFlagFalse()) {
                this._clearStorage();
            }
            this._setCurrentCidHash();
        },

        /**
         * Set defaults.
         *
         * @return {void}
         * @private
         */
        
        _setDefaults: function () {
            if (!this.storage.isSet('giftcard_message')) {
                this.storage.set('giftcard_message', $t(this.options.defaultMessage));
            }

            if (!this.storage.isSet('giftcard_sender_name') && customer.isLoggedIn()) {
                this.storage.set('giftcard_sender_name', customer.getFirstName());
            }
        },

        /**
         * Init handlers.
         *
         * @return {void}
         * @private
         */
        
        _initHandlers: function () {
            $(document).on('buyPressed', this._onSubmitHandler.bind(this));
        },

        /**
         * Fill form data.
         *
         * @return {void}
         * @private
         */
        
        _fillForm: function () {
            var productInCart = this.storage.get('giftcard_cover_product_id'),
                isCurrentCover = productInCart !== undefined && productInCart === this.currentCoverId,
                inputs = $(this.element).find(':input:not(:disabled)'),
                name,
                item,
                self = this;

            inputs.each(function () {
                item = $(this);
                name = item.attr('name');
                if ((isCurrentCover || name === 'giftcard_sender_name') && self.storage.isSet(name)) {
                    item.parent().siblings('label').addClass('active');
                    item.val(self.storage.get(name));
                } else if (isCurrentCover && self.storage.isSet(name)) {
                    if (name === "qty") {
                        item.find('option[value="' + self.storage.get(name) + '"]').attr('selected', true);
                    } else {
                        item.val(self.storage.get(name));
                    }
                }
            });
        },

        /**
         * On submit form handler.
         *
         * @param {Object} event
         * @param {HTMLElement} form
         * @private
         */
        
        _onSubmitHandler: function (event, form) {
            var inputs = $(form).find(':input:not(:disabled)'),
                name,
                value,
                _self = this;

            inputs.each(function () {
                name = $(this).attr('name');
                value = $(this).val().trim();

                if (name) {
                    _self.storage.set(name, value);
                }
            });

            customerData.invalidate(['messages']);
            $.mage.cookies.set('allow_login', true);
        },

        /**
         * Check if *CID* has changed.
         *
         * @return {Boolean}
         * @private
         */
        _isCidWasChanged: function () {
            return this._getPreviousCidHash() !== this._getCurrentCidHash();
        },


        /**
         * Get previous *CID* hash.
         *
         * @return {String}
         * @private
         */
        _getPreviousCidHash: function () {
            return this.storage.get('cid_hash');
        },

        /**
         * Get guest hash
         * @returns {String}
         * @private
         */
        _getGuestCidHash: function() {
            return md5('guest').toString();
        },

        /**
         * Get current *CID* hash.
         *
         * @return {String}
         * @private
         */
        _getCurrentCidHash: function () {
            return md5($.mage.cookies.get('CID') || 'guest').toString();
        },

        /**
         * Set current *CID* has.
         *
         * @return {void}
         * @private
         */
        _setCurrentCidHash: function () {
            this.storage.set('cid_hash', this._getCurrentCidHash());
        },

        /**
         * Check if *PZN* is set.
         *
         * @return {Boolean}
         * @private
         */
        _isPznFlagFalse: function () {
            return this.storage.get('PZN') === false;
        },

        /**
         * Check if *PZN* flag has to be set.
         *
         * @return {boolean}
         * @private
         */
        _isNeedToSetPznFlag: function () {
            return this.options.PZN !== null;
        },

        /**
         * Set *PZN* flag.
         *
         * @param {Boolean} [PZN]
         * @return {Boolean}
         * @private
         */
        _setPznFlag: function (PZN) {
            return this.storage.set('PZN', typeof PZN === 'undefined' ? this.options.PZN : PZN);
        },

        /**
         * Clear storage.
         *
         * @return {void}
         * @private
         */
        _clearStorage: function () {
            this.storage.removeAll();
        }
    });

    return $.incomm.manageGiftcardForm;
});

