/**
 * Copyright © InComm, Inc. All rights reserved.
 */
define('InComm_Login/js/clearLoginCookie',[
    'jquery',
    'uiComponent',
    'mage/cookies'
], function ($, Component) {
    'use strict';

    return Component.extend({
        allowLoginCookie: 'allow_login',

        initialize: function () {
            if ($.mage.cookies.get(this.allowLoginCookie) !== null) {
                $.mage.cookies.clear(this.allowLoginCookie);
            }

            this._super();
        }
    });
});

