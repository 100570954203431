/*
 * Copyright © InComm, Inc. All rights reserved.
 */

define('InComm_Login/js/customer',[
    'jquery',
    'mage/mage',
    'mage/cookies'
], function ($) {
    'use strict';

    return (function() {
        /** @var {String} */
        var CID_COOKIE = 'CID';

        /** @var {String} */
        var CUSTOMER_COOKIE = 'customer';

        /**
         * Check if customer is logged in.
         *
         * @return {boolean}
         */
        var isLoggedIn = function() {
            return $.mage.cookies.get(CID_COOKIE) !== null && $.mage.cookies.get(CUSTOMER_COOKIE) !== null;
        };

        /**
         * Get first name.
         *
         * @return {string}
         */
        var getFirstName = function() {
            var cookie = $.mage.cookies.get(CUSTOMER_COOKIE);
            var customer = JSON.parse(cookie) || {};
            if (typeof customer.firstName === 'undefined') {
                window.console && console.log('Customer cookie has no *firstName* field.');
                return '';
            }

            return customer.firstName;
        };

        return {
            isLoggedIn: isLoggedIn,
            getFirstName: getFirstName
        }
    })();
});

